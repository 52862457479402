import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './styles.scss';
import latestClaims from '../../images/products/latestClaims.jpg';
import utilities from '../../images/products/utilities.jpg';
import card from '../../images/products/creditProducts.jpg';
import insurance from '../../images/products/insurance.jpg';
import purse from '../../images/products/debtHelp.jpg';

const Products = ({ location }) => (
  <section className="products">
    <div className="container">
      <div className="products__wrapper">
        <div className="products__product" id="latestClaims">
          <div className="products__text">
            <h3 className="products__heading">
              Compensation Claims
            </h3>
            <p className="products__para">
              Could you be owed you owed £££s in compensation? Discover the ways that you could
              claim today.
            </p>
            <Link className="products__link" to={`/news/${location.search}`} state={{ scrollTo: 'latestClaims' }}>
              See claims news &gt;
            </Link>
          </div>
          <div className="products__image">
            <img src={latestClaims} alt="Claims" />
          </div>
        </div>
        <div className="products__product" id="utilities">
          <div className="products__text">
            <h3 className="products__heading">
              Utilities (Broadband & Energy)
            </h3>
            <p className="products__para">
              Paying too much on your home broadband? Thinking of switching energy provider?
              See news and offers that could help lower your monthly utility bills.
            </p>
            <Link className="products__link" to={`/news/${location.search}`} state={{ scrollTo: 'utilities' }}>
              Select utility services &gt;
            </Link>
          </div>
          <div className="products__image">
            <img src={utilities} alt="Utilities" />
          </div>
        </div>

        <div className="products__product" id="credit">
          <div className="products__text">
            <h3 className="products__heading">
              Credit products
            </h3>
            <p className="products__para">
              Planning an expensive purchase? Looking for great deals on credit cards
              and loans? Discover a range of options to suit your needs.
            </p>
            <Link className="products__link" to={`/news/${location.search}`} state={{ scrollTo: 'creditProducts' }}>
              Choose credit services &gt;
            </Link>
          </div>
          <div className="products__image">
            <img src={card} alt="Credit card" />
          </div>
        </div>

        <div className="products__product" id="insurance">
          <div className="products__text">
            <h3 className="products__heading">
              Insurance
            </h3>
            <p className="products__para">
              Searching for low-premium insurance policies? Explore a range of financial
              products that could help you cover yourself and your family.
            </p>
            <Link className="products__link" to={`/news/${location.search}`} state={{ scrollTo: 'insurance' }}>
              Browse insurance offers &gt;
            </Link>
          </div>
          <div className="products__image">
            <img className="products__insurance" src={insurance} alt="Insurance" />
          </div>
        </div>
        <div className="products__product" id="debt">
          <div className="products__text">
            <h3 className="products__heading">
              Debt Management
            </h3>
            <p className="products__para">
              Looking to resolve debt issues? Searching for easy-to-understand money-management
              options? Check out the latest news and offers from our trusted partners.
            </p>
            <Link className="products__link" to={`/news/${location.search}`} state={{ scrollTo: 'debtHelp' }}>
              Explore debt services &gt;
            </Link>
          </div>
          <div className="products__image">
            <img src={purse} alt="Purse" />
          </div>
        </div>
      </div>

    </div>
  </section>
);

Products.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Products;
