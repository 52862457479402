import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PagesHero from '../components/PagesHero';
import Products from '../components/Products';
import Newsletter from '../components/Newsletter';
import Border from '../components/Border';

import newsletterS from '../images/newsletterServices.jpg';
import newsletterL from '../images/newslettersServ-large.jpg';

const Services = ({ location }) => {
  useEffect(() => {
    if (location.state?.scrollTo) {
      scroller.scrollTo(location.state.scrollTo, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  });

  return (
    <Layout location={location}>
      <SEO title="Services" />
      <PagesHero services location={location} />
      <Products location={location} />
      <Border />
      <Newsletter
        location={location}
        imageSmall={newsletterS}
        imageLarge={newsletterL}
        title="FREE Newsletter "
        paragraph="Sign up to our FREE newsletter to receive the latest financial news and offers straight to your inbox. Change your preferences at any time. "
      />
    </Layout>
  );
};

Services.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default Services;
