import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './styles.scss';

const PagesHero = ({
  location, services, aboutUs, news,
}) => (
  <section className={`
    pages-hero
    ${services ? 'pages-hero__services' : ''}
    ${aboutUs ? 'pages-hero__about' : ''}
    ${news ? 'pages-hero__news' : ''}
    `}
  >
    <div className="pages-hero__container">
      {services && (
        <div className={`pages-hero__content ${services ? 'pages-hero__content--services' : ''}`}>
          <h3 className="pages-hero__header">
            Money Saving Service
          </h3>
          <p className="pages-hero__para">
            We exist to help you discover a range of financial products that could save you money.
          </p>
          <Link to={`/sign-up/${location.search}`} className="mainButton pages-hero__cta">
            Send me offers &gt;
          </Link>
        </div>
      )}
      {aboutUs && (
        <div className={`pages-hero__content ${aboutUs ? 'pages-hero__content--about' : ''}`}>
          <h3 className="pages-hero__header">
            About My Money Back
          </h3>
          <p className="pages-hero__para">
            <strong>
              Tired of checking and rechecking comparison sites? Ready to explore great deals on a
              range of financial products? All with notifications sent straight to your inbox? We
              can help.
            </strong>
          </p>
          <p className="pages-hero__para">
            From home broadband and energy tariffs to compensation claims and debt management
            services, we’ll only send news and offers based on your preferences.
          </p>
          <p className="pages-hero__para">
            Sound good to you? Let’s get started. For convenient access to the financial products
            that matter to you, complete your FREE subscription today.
          </p>
          <Link to={`/sign-up/${location.search}`} className="mainButton pages-hero__cta">
            Keep me updated &gt;
          </Link>
        </div>
      )}
      {news && (
        <div className={`pages-hero__content ${news ? 'pages-hero__content--news' : ''}`}>
          <h3 className="pages-hero__header">
            News
          </h3>
          <p className="pages-hero__para">
            <strong>
              See the latest money news and claims related articles from across the web.
            </strong>
          </p>
        </div>
      )}
    </div>
  </section>
);

PagesHero.defaultProps = {
  services: false,
  aboutUs: false,
  news: false,
};

PagesHero.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  services: PropTypes.bool,
  aboutUs: PropTypes.bool,
  news: PropTypes.bool,
};

export default PagesHero;
